var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _vm.user.id
                                ? _c("v-card-title", [
                                    _vm._v("Editing " + _vm._s(_vm.oldName)),
                                  ])
                                : _c("v-card-title", [
                                    _vm._v("Creating new user"),
                                  ]),
                              _c(
                                "v-card-text",
                                [
                                  _c("m-field", {
                                    attrs: { m: "user", a: "name" },
                                    model: {
                                      value: _vm.user.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "name", $$v)
                                      },
                                      expression: "user.name",
                                    },
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("m-field", {
                                            attrs: {
                                              m: "user",
                                              a: "role",
                                              component: "v-select",
                                              items: _vm.rules.role.oneOf,
                                            },
                                            model: {
                                              value: _vm.user.role,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.user, "role", $$v)
                                              },
                                              expression: "user.role",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("m-field", {
                                            attrs: {
                                              m: "user",
                                              a: "status",
                                              component: "v-select",
                                              items: _vm.rules.status.oneOf,
                                            },
                                            model: {
                                              value: _vm.user.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "user.status",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("m-field", {
                                            attrs: { m: "user", a: "phone" },
                                            model: {
                                              value: _vm.user.phone,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.user, "phone", $$v)
                                              },
                                              expression: "user.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "8" } },
                                        [
                                          _c("m-field", {
                                            attrs: { m: "user", a: "email" },
                                            model: {
                                              value: _vm.user.email,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.user, "email", $$v)
                                              },
                                              expression: "user.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "11" } },
                                        [
                                          _c("field", {
                                            attrs: { name: "Password" },
                                            model: {
                                              value: _vm.user.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "user.password",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generatePassword()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-small": "" } },
                                                [_vm._v("fa fa-sync")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              947743126
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }